import { Dispatch } from "redux";
import { UploadTypes } from ".";
import axios, { AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download'
import { ErrorHandler } from "utils";

const fileURL = process.env.REACT_APP_FILE_URL

export const uploadDocument = (files: any, props: any) => async (dispatch: Dispatch) => {
  dispatch({ type: UploadTypes.Loading });

  const { setFieldValue, setFieldError, uploadField, fieldName, fieldUrl, errorField } = props

  const formData = new FormData()
  Array.from(files).forEach((file: any) => {
    formData.append('files', file)
  })

  try {
    const res = await axios.post(`${fileURL}uploads`, formData, {
      headers: {
        'x-auth': '1a9b83aa-8af3-485a-a398-13cb28475684',
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    const { data } = res;
    setFieldValue(uploadField, false, false)
    setFieldValue(fieldName, data.data[0]?.name, false)
    setFieldValue(fieldUrl, data.data[0]?.url, false)
    dispatch({ type: UploadTypes.UploadDocument, payload: { data } });

  } catch (error: any) {
    console.log(error)
    const data = ErrorHandler(error, 'File server');
    setFieldError(errorField, data["message"]);
    setFieldValue(uploadField, false, false)
    dispatch({ type: UploadTypes.UploadDocument, payload: { data } });
  }
};

export const uploadManyDocuments = (files: any, helpers: any) => async (dispatch: Dispatch) => {

  const { push, setFieldError, fieldName, uploadField, setFieldValue } = helpers;

  const formData = new FormData();

  Array.from(files).forEach((file: any) => {
    formData.append('files', file)
  })

  try {
    const res = await axios.post(`${fileURL}uploads`, formData, {
      timeout: 10000,
      headers: {
        'x-auth': '1a9b83aa-8af3-485a-a398-13cb28475684'
      }
    })

    const { data } = res;
    setFieldValue(uploadField, false, false)
    for (const item of data?.data) {
      push({
        name: item.name,
        link: item.url
      })
    }

    dispatch({ type: UploadTypes.UploadManyDocuments, payload: { data } });

  } catch (error) {
    console.log(error)
    const data = ErrorHandler(error, 'File server');
    setFieldError(fieldName, data["message"]);
    setFieldValue(uploadField, false, false)
    dispatch({ type: UploadTypes.UploadManyDocuments, payload: { data } });
  }

};

export const getDocument = (props: any) => async (dispatch: Dispatch) => {
  dispatch({ type: UploadTypes.Loading });

  const { link, name } = props;

  let fileType = "other"
  if (link.match(/\.(jpeg|jpg|gif|png)$/) != null) {
    fileType = "image";
  }
  if (link.match(/\.(pdf)$/) != null) {
    fileType = "pdf";
  }

  let data = {
    name,
    type: fileType,
    status: 200,
    message: 'FOUND',
    url: ''
  };

  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      timeout: 10000,
      url: `${fileURL}${link}`,
      headers: {
        "x-auth": "1a9b83aa-8af3-485a-a398-13cb28475684"
      },
      responseType: "blob",
    }

    const res = await axios(config);

    if (fileType === "image") {
      data["url"] = URL.createObjectURL(res.data);
    }

    if (fileType === "pdf") {
      const blob = new Blob([res.data], { type: "application/pdf" });
      data["url"] = URL.createObjectURL(blob);
    }


    if (fileType === "other") {
      data["url"] = res.data;
    }

    dispatch({ type: UploadTypes.GetDocument, payload: { data } });

  } catch (error: any) {
    if (error?.response?.status === 404) {
      data["status"] = 404;
      data["message"] = "File not found"
    } else {
      data["status"] = 500
      data["message"] = ErrorHandler(error, 'File server')["message"];
    }

    dispatch({ type: UploadTypes.GetDocument, payload: { data } });
  }
};

export const getManyDocuments = (files: any[]) => async (dispatch: Dispatch) => {
  dispatch({ type: UploadTypes.Loading });

  const links = files.map(async (file) => {
    const { name, link } = file;

    let fileType = "other"
    if (link.match(/\.(jpeg|jpg|gif|png)$/) != null) {
      fileType = "image";
    }
    if (link.match(/\.(pdf)$/) != null) {
      fileType = "pdf";
    }
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${fileURL}${link}`,
      headers: {
        "x-auth": "1a9b83aa-8af3-485a-a398-13cb28475684"
      },
      responseType: "blob",
    }

    let data = {
      name,
      type: fileType,
      status: 200,
      message: 'FOUND',
      url: ''
    };

    try {
      const res = await axios(config);
      if (fileType === "image") {
        data["url"] = URL.createObjectURL(res.data);
      }

      if (fileType === "pdf") {
        const blob = new Blob([res.data], { type: "application/pdf" });
        data["url"] = URL.createObjectURL(blob);
      }


      if (fileType === "other") {
        data["url"] = res.data;
      }

    } catch (error: any) {
      if (error?.response?.status === 404) {
        data["status"] = 404;
        data["message"] = "File not found"
      } else {
        data["status"] = 500
        data["message"] = ErrorHandler(error, 'File server')["message"];
      }
    }

    return data;
  })

  const results = await Promise.all(links);
  dispatch({ type: UploadTypes.GetManyDocuments, payload: { data: results } });

}

export const downloadUserGuideAction = (props: any) => async (dispatch: Dispatch) => {
  let data: any = {
    loading: true,
    error: false,
    message: null,
    data: null
  }

  dispatch({ type: UploadTypes.DownloadUserGuide, payload: { data } });
  const { link } = props;

  try {

    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${fileURL}${link}`,
      headers: {
        "x-auth": "1a9b83aa-8af3-485a-a398-13cb28475684"
      },
      responseType: "blob",
    }

    const res = await axios({ ...config, timeout: 10000 });

    data = {
      loading: false,
      error: false,
      message: 'user guide downloaded successfully.',
      data: res.data
    }

    fileDownload(res.data, 'userguide.pdf');
    dispatch({ type: UploadTypes.DownloadUserGuide, payload: { data } });

  } catch (error: any) {
    data = {
      loading: false,
      error: true,
      message: error.code === "ECONNABORTED" ? "Uploaded file is invalid,try another file" : error?.message,
      data: error.code === "ECONNABORTED" ? null : error?.response?.data,
    }

    dispatch({ type: UploadTypes.DownloadUserGuide, payload: { data } });

  }
}

export const resetUploadStateAction = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: UploadTypes.ResetPayload, payload: { data } })
}


export const addDocumentsAction = async (files: any) => {

  const formData = new FormData();

  Array.from(files).forEach((file: any) => {
    formData.append('files', file)
  })

  try {
    const res = await axios.post(`${fileURL}uploads`, formData, {
      headers: {
        'x-auth': '1a9b83aa-8af3-485a-a398-13cb28475684'
      }
    })
    return Promise.resolve(res);

  } catch (error) {
    return Promise.reject(error);
  }
}

export const getDocumentAction = async (file: any) => {

  const { link } = file;
  
  const config: AxiosRequestConfig = {
    method: "GET",
    url: `${fileURL}${link}`,
    headers: {
      "x-auth": "1a9b83aa-8af3-485a-a398-13cb28475684"
    },
    responseType: 'blob'
  }

  return await axios(config);

}

export const getDocumentsAction = async (files: any) => {

  return files.map(async (file: any) => {
    const { link } = file;

    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${fileURL}${link}`,
      headers: {
        "x-auth": "1a9b83aa-8af3-485a-a398-13cb28475684"
      },
      responseType: "blob",
    }

    try {
      const res = await axios(config);
      console.log(res, 'the response');

    } catch (error) {
      console.log(error)
    }

  })
}