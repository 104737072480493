import React, { FC, useEffect, useState } from "react";
import { useQuery } from 'react-query'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage } from 'formik'
import Select from 'react-select'
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";

import { getUserTypesAction } from "../../../reduxes/user";
import { nationalities } from './schema'
type Props = {
  values: any;
  setFieldValue: any;
  lang: "en" | "fr" | "rw";
  selectedValue: any;
  setSelectedValue: any;
  selectNationality: any;
  setSelectNationality: any
};


const SignUpFields: FC<Props> = ({ values, setFieldValue, lang, setSelectedValue, selectedValue, setSelectNationality }) => {

  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false)

  const [options, setOptions] = useState<any[]>([]);

  const handleShowPassword = () => setShowPassword(!showPassword)

  const handleUserType = (val: any, props: any) => {
    const { setFieldValue } = props;
    setSelectedValue(val);
    setFieldValue('userType', val?.value);
  }

  const { isLoading: userTypesLoading, data: results } = useQuery(['fetch user types'], () => {
    return getUserTypesAction("user type")
  }, {
    retry: false,
    keepPreviousData: true,
  })

  useEffect(() => {
    if (results) {
      const userTypes = results?.data;

      const value = values["accountType"];

      const opt = userTypes?.data?.filter((el: any) => {
        if (value === "company") {
          return el.value.for !== "individual"
        }
        return el.value.for !== "company"
      })

      const other = opt?.find((el: any) => el.value.en === "Other");

      const newValues = [...opt?.filter((el: any) => el.value.en !== "Other"), other].map((el: any) => {
        const obj = {
          value: el?.id,
          label: el?.value[lang],
          shouldVerify: el?.value?.shouldVerify
        }
        return obj;
      });
      setOptions([...newValues])
    }
  }, [values, results, lang])

  return (
    <>
      <div className="flex flex-col mb-5">

        {
          values["accountType"] === "individual" ?
            <label className="text-sm mb-2">
              {t('signup.individual_fields.individual_type')}*
            </label>
            :
            <label className="text-sm mb-2">
              {t('signup.company_fields.company_type')}*
            </label>
        }

        <Select
          options={options}
          className="outline-none"
          value={selectedValue}
          onChange={(val: any) => {
            handleUserType(val, { setFieldValue });
          }}
          noOptionsMessage={() => 'No user types Found'}
          isLoading={userTypesLoading}
        />

        <ErrorMessage name="userType" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

      </div>


      <div className="relative flex flex-col mb-5">
        <label className="text-sm mb-3">
          {`${values["accountType"] === "individual" ? t('signup.individual_fields.nationality') : t('signup.company_fields.nationality')}*`}
        </label>

        <Select
          options={nationalities.filter(el => el.for === values["accountType"]).map((el: any) => {
            return {
              value: el["en"].toLowerCase(),
              label: el[lang]
            }
          })}
          onChange={(val) => {
            setSelectNationality(val);
            setFieldValue("nationality", val?.value);
          }}
        />

        <ErrorMessage name="nationality" render={msg => <div style={{ color: 'red' }}>{msg}</div>} />

      </div>


      <div className="flex flex-col mb-5">
        <label className="text-sm">{t('signup.company_fields.email_label')}*</label>
        <Field
          className="h-12 px-3 mt-2 text-sm bg-white border-0 rounded outline-none"
          type="email"
          name="email"
          placeholder={
            values["accountType"] === "company" ? `${t('signup.company_fields.email_placeholder')}` : `${t('signup.individual_fields.email_placeholder')}`
          }
        />

        <ErrorMessage name="email" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

      </div>

      <div className="flex flex-col mb-5">
        <label className="text-sm">{t('signup.company_fields.phone_label')}*</label>
        <PhoneInput
          inputClass="h-12 px-3 mt-2 text-sm bg-white border-0 rounded outline-none"
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          country={(values["nationality"].toLowerCase() === "rwandan" || values["nationality"].toLowerCase() === "national" || values["nationality"]?.length === 0) ? "rw" : 'us'}
          disableDropdown={(values["nationality"].toLowerCase() === "rwandan" || values["nationality"].toLowerCase() === "national" || values["nationality"]?.length === 0)}
          value={values.phoneNumber}
          placeholder={
            values["accountType"] === "company"
              ? `${t('signup.company_fields.phone_placeholder')}`
              : `${t('signup.individual_fields.phone_placeholder')}`
          }
          onChange={(val) => setFieldValue("phoneNumber", `+${val}`, false)}
        />
        <ErrorMessage name="phoneNumber" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

      </div>

      <div className="flex flex-col mb-5">
        <label className="text-sm">{t('signup.company_fields.password')}*</label>
        <div className="h-12 bg-white flex">
          <Field
            className="text-sm border-0 rounded outline-none flex-grow px-3"
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder={
              values["accountType"] === "company"
                ? `${t('signup.company_fields.password_placeholder')}`
                : `${t('signup.individual_fields.password_placeholder')}`
            }
          />

          <span
            className="flex justify-center items-center h-full p-3 rounded-r cursor-pointer"
            onClick={() => handleShowPassword()}
          >
            {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
          </span>
        </div>


        <ErrorMessage name="password" render={msg => <div style={{ color: 'red' }} > {msg}</div >} />

      </div>
    </>
  );
};

export default SignUpFields;
