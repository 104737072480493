import React, { useState, FC } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useMutation } from 'react-query';

import { Formik, Form } from "formik";
import { object } from "yup";

import { theme } from "./Utils/theme";
import { Theme, makeStyles, createStyles, ThemeProvider } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Step, StepLabel, Stepper } from "@material-ui/core";

import GetStepContent from "./Contents/Content";

import { createApplicationAction, updateApplicationAction } from "../../../reduxes/applications";

import { AppLoader, SnackMessage } from "../../Reusable";
import { ErrorHandler } from "utils";

type Props = {
    action: any;
    setAction: any;
    formDetails: any;
    lang: "en" | "fr" | "rw";
    mode: string,
    response: any
};

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    })
);

const RenderForm: FC<Props> = ({ lang, mode, formDetails, setAction, action, response }) => {

    const classes = useStyles();
    const navigate = useNavigate();

    const location = useLocation() as any;

    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [clickedBtn, setClickedBtn] = useState("");

    const [openSnack, setOpenSnack] = useState(false)
    const [snackMessage, setSnackMessage] = useState<any>(null);

    const userState = useSelector((state: any) => state.users);

    const { user } = userState

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCurrentPage(currentPage - 1)
    };

    const { titleArr, validationSchema, formData } = formDetails;

    const stepName = titleArr[activeStep];

    const handleClickedBtn = (btn: string, values: any) => {
        setClickedBtn(btn)
        if (btn === "draft") {
            setAction({
                triggered: true,
                action: 'draft'
            })
            values["language"] = lang;
            handleSubmission(values, 'draft');
        }
    }

    const performActionOnMutation = (values: any) => {
        if (mode === 'new') {
            return createApplicationAction({ ...values });
        } else {
            const id = new URLSearchParams(location.search).get("id") as string;
            return updateApplicationAction(id, { ...values })

        }

    }

    const { isLoading, mutateAsync } = useMutation((values: any) => {
        return performActionOnMutation(values)
    }, {
        retry: false,
        onSettled: (data: any, error: any) => {

            let message = data?.data?.message;
            if (error) {
                const { message: res } = ErrorHandler(error, 'server');
                message = res;
            }
            setOpenSnack(true)
            setSnackMessage({
                error: error ? true : false,
                message
            })

            if (data) {
                const response = data?.data?.data;
                setTimeout(() => {
                    navigate(response?.status === 'draft' ? '/drafts' : '/overview');
                }, 1500)
            }
        }
    })

    const handleSubmission = async (values: any, status: string) => {
        try {
            let parsedVal;
            if (mode === 'edit') {
                parsedVal = {
                    applicationJSON: { ...values },
                    status
                }
            } else {
                parsedVal = {
                    userId: user?.data?.id,
                    serviceId: (mode === "new") ? response?.data?.id : mode === "edit" ? response?.data?.service.id : null,
                    departmentId: response?.data?.department?.id || null,
                    divisionId: response?.data?.division?.id || null,
                    applicationJSON: values,
                    status
                }
            }
            await mutateAsync({ ...parsedVal })
        } catch (error) {
            return error;
        }
    }

    return (
        <div className="flex flex-col flex-grow">
            <div className={`${classes.root}`}>
                <ThemeProvider theme={theme}>
                    <div className="hidden md:block">
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {
                                titleArr.map((label: string, index: number) => {
                                    if (label === "fetches" && user?.data.nationality === "foreigner") {
                                        label = `fetches.foreigner`
                                    }

                                    if (label === "fetches") {
                                        label = `fetches.normal`
                                    }

                                    return (
                                        <Step key={index}>
                                            <StepLabel>{t(`service.application.steps.${label}`)}</StepLabel>
                                        </Step>
                                    )
                                }
                                )
                            }
                        </Stepper>
                    </div>
                </ThemeProvider>
            </div>

            <Formik
                initialValues={formData}
                validationSchema={object({ [stepName]: validationSchema[stepName] })}
                onSubmit={(values, actions) => {

                    setAction({
                        triggered: true,
                        action: clickedBtn
                    })

                    values["language"] = lang;
                    if (clickedBtn === "next" && stepName !== "notification") handleNext();

                    if ((clickedBtn === "submit" && stepName === "notification")) {
                        const price = mode === "new" ? response.data.price : response?.data?.service?.price;
                        const priceInNumber = Number(price.replace(",", ""));
                        const status = (priceInNumber === 0 || (mode === "edit" && response.data?.status === "missing-information")) ? 'under-review' : 'payment-pending';
                        handleSubmission({ ...values }, status);
                    }

                }}
            >
                {({ values, setFieldValue, setFieldError, setFieldTouched, setTouched, touched }) => {
                    return (
                        <Form className="bg-white flex flex-col text-gray-600 p-2 flex-grow">
                            <div className="flex-grow mb-5">
                                <GetStepContent
                                    stepName={stepName}
                                    json={formData}
                                    values={values}
                                    lang={lang}
                                    formik={{
                                        setFieldError,
                                        setFieldValue,
                                        setTouched,
                                        setFieldTouched,
                                        touched
                                    }}
                                    user={user}
                                />
                            </div>

                            <div className="flex flex-col md:flex-row md:justify-between">
                                <button
                                    type="button"
                                    onClick={() => handleClickedBtn('draft', values)}
                                    className={` text-white rounded buttonsColor md:w-60 focus:outline-none cursor-pointer flex justify-center items-center p-2 mb-3`}>
                                    {
                                        isLoading && action?.action === "draft" ?
                                            <AppLoader />
                                            :
                                            t("service.application.buttons.draft")
                                    }
                                </button>

                                <div className="flex justify-between items-center space-x-2">
                                    <button
                                        type="button"
                                        className=" bg-white rounded w-44 p-2 focus:outline-none border border-gray-300 flex justify-center items-center"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        {t("service.application.buttons.previous")}
                                    </button>
                                    <button
                                        type="submit"
                                        onClick={() => handleClickedBtn(stepName !== "notification" ? "next" : "submit", values)}
                                        className="buttonsColor rounded w-44 p-2 focus:outline-none flex justify-center text-white"
                                    >
                                        {
                                            isLoading && action?.action === "submit" ?
                                                <AppLoader />
                                                :
                                                stepName === "notification" ? t('service.application.buttons.submit') : t("service.application.buttons.next")
                                        }

                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
            {openSnack && <SnackMessage open={openSnack} handleCloseSnack={() => setOpenSnack(false)} successMessage={snackMessage} />}
        </div>
    )
};

export default RenderForm;
