import React, { ChangeEvent, FC, useState } from "react";
import { useQuery, useMutation } from 'react-query'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";

import {
    sendCommunicationAction,
    getAllDepartmentsAction,
} from "../../../reduxes/communications";

import {
    SnackMessage,
    ButtonLoader,
    UploadFile,
    AppLoader,
    Error
} from "../../Reusable";

import { SocketState } from "../../../context";
import { ErrorHandler } from "utils";

type Props = {
    lang: "en" | "fr" | "rw";
    setActiveSelection: any;
};

const messageTypeOptions = [
    {
        id: 1,
        name: {
            en: "Claim",
            fr: "Plainte",
            rw: "Ibirego",
        },
    },
    {
        id: 2,
        name: {
            en: "Request",
            fr: "Demande",
            rw: "Ubusabe",
        },
    },
    {
        id: 3,
        name: {
            en: "Invoice",
            fr: "Facture",
            rw: "Kwishyuza",
        },
    },
    {
        id: 4,
        name: {
            en: "Reports",
            fr: "Rapports",
            rw: "Raporo",
        },
    },
    {
        id: 6,
        name: {
            en: "Others",
            fr: "Autres",
            rw: "Izindi",
        },
    },
];

// used to display validation error in different languages
const errorMessages = {
    to: {
        en: "Select the receiving department",
        fr: "Sélectionnez le département de réception",
        rw: "Hitamo ishami ryakira ubutumwa",
    },
    division: {
        en: "Select the division",
        fr: "Sélectionnez le division",
        rw: "Hitamo diviziyo",
    },
    subject: {
        en: "Provide the communication subject",
        fr: "Indiquez l'objet de la communication",
        rw: "Tanga ingingo",
    },
    messageType: {
        en: "Provide the communication type",
        fr: "Indiquez le type de communication",
        rw: "Tanga ubwoko bwitumanaho",
    },
    message: {
        en: "Provide the communication message",
        fr: "Fournir le message de communication",
        rw: "Tanga ubutumwa bwitumanaho",
    },
};

// used to display the send button in different languages
const sendButton = {
    en: "Send",
    fr: "Envoyer",
    rw: "Ohereza",
};

const NewMessage: FC<Props> = ({ lang, setActiveSelection }) => {
    const { t } = useTranslation();

    const { socket } = SocketState() as any;

    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState<any>({
        error: false,
        message: "",
    });

    const handleDepartment = (value: string, helpers: any) => {
        const { options, setFieldValue } = helpers;

        const dep = options?.find((el: any) => el?.id === value);
        setFieldValue("to.hasDivisions", dep?.divisions?.length > 0 ? true : false);
        setFieldValue('division.items', [...dep?.divisions]);
        if (dep?.divisions?.length === 0) setFieldValue('division.id', '')
    }


    const handleClose = () => {
        setOpen(false);
    };

    const { isLoading: loadingDepartments, isError: isDepartmentError, error: departmentError, refetch, data: departmentData } = useQuery('get departments', () => {
        return getAllDepartmentsAction()
    }, {
        retry: false,
        keepPreviousData: true,
    })


    const { isLoading, mutateAsync } = useMutation((values: any) => {
        return sendCommunicationAction(values)
    }, {
        retry: false,
        onError: (error: any) => {
            const data = ErrorHandler(error, 'server');
            setOpen(true);
            setSnackMessage({
                error: true,
                message: data?.message
            });
        },
        onSuccess: (data: any) => {
            setOpen(true);
            setSnackMessage({
                error: false,
                message: "Message sent successfully."
            });

            
            const response = data?.data;
            socket.emit("new message", response.data);

            setTimeout(() => {
                setActiveSelection('all');
            }, 1500)
        }
    })


    if (loadingDepartments) {
        return (
            <div className="flex justify-center items-center bg-white h-full rounded">
                <AppLoader />
            </div>
        )
    }

    if (isDepartmentError) {
        return (
            <div className="flex justify-center items-center bg-white h-full rounded">
                <Error error={departmentError} action={() => refetch()} />
            </div>
        )
    }


    return (
        <div className="flex flex-col h-full">
            <Formik
                initialValues={{
                    to: {
                        id: "",
                        hasDivisions: false,
                        items: departmentData?.data?.data
                    },
                    division: {
                        id: '',
                        items: []
                    },
                    subject: "",
                    messageType: "",
                    message: "",
                    attachments: [],
                }}
                validationSchema={Yup.object().shape({
                    to: Yup.object().shape({
                        id: Yup.string().required(errorMessages["to"][lang]),
                        hasDivisions: Yup.boolean(),
                        items: Yup.array()
                    }),
                    division: Yup.object().when('to', (value: any, schema: any) => {
                        if (value.hasDivisions) {
                            schema = schema.shape({
                                id: Yup.string().required(errorMessages["division"][lang]),
                                items: Yup.array()
                            })
                        } else {
                            schema = schema.shape({
                                id: Yup.string(),
                                items: Yup.array()
                            })
                        }
                        return schema;
                    }),
                    subject: Yup.string().required(errorMessages["subject"][lang]),
                    messageType: Yup.string().required(
                        errorMessages["messageType"][lang]
                    ),
                    message: Yup.string().required(errorMessages["message"][lang]),
                    attachments: Yup.array().nullable(),
                })}
                onSubmit={async (values, actions) => {
                    const body = {
                        to: {
                            id: values["to"]["id"]
                        },
                        divisionId: values["division"]["id"]?.length > 0 ? values["division"]["id"] : null,
                        subject: values["subject"],
                        message: values["message"],
                        messageType: values["messageType"],
                        attachments: values["attachments"]
                    };

                    try {
                        await mutateAsync({ ...body });
                        actions.resetForm();
                    } catch (error) {
                        return error;
                    }
                }}
            >
                {({
                    values,
                    setFieldValue,
                    setFieldError,
                    setFieldTouched,
                }) => {
                    return (
                        <Form className="flex flex-col text-sm h-full bg-white p-2">
                            <div className={`grid grid-cols-${values["to"]["hasDivisions"] ? '2' : '1'} gap-2`}>
                                <div className="flex flex-col mb-3">

                                    <Field
                                        as="select"
                                        name="to.id"
                                        className="w-full border border-gray-200 focus:outline-none h-12 rounded bg-white"
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            const val = e.target.value;
                                            setFieldValue('to.id', val);
                                            handleDepartment(val, {
                                                setFieldValue,
                                                options: values["to"]["items"]
                                            })
                                        }}
                                    >
                                        <option value="" disabled> Select the department </option>
                                        {values["to"]["items"]?.map((department: any, ind: number) => {
                                            const name = department?.jsonName[lang];
                                            return (
                                                <option value={department.id} key={ind}>
                                                    {name}
                                                </option>
                                            );
                                        })}
                                    </Field>

                                    <ErrorMessage
                                        name="to.id"
                                        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                                    />
                                </div>

                                {values["to"]["hasDivisions"] && (
                                    <div className="flex flex-col mb-3">
                                        <Field
                                            as="select"
                                            name="division.id"
                                            className="w-full border border-gray-200 focus:outline-none h-12 rounded bg-white"
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value;
                                                setFieldValue('division.id', val);
                                            }}
                                        >
                                            <option value="" disabled>
                                                {t("communication.newMessage.select division")}
                                            </option>
                                            {values["division"]["items"]?.map((div: any, ind: number) => {
                                                const name = div?.jsonName[lang];
                                                return (
                                                    <option value={div.id} key={ind}>
                                                        {name}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                        <ErrorMessage
                                            name="division.id"
                                            render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                                        />
                                    </div>
                                )}

                            </div>


                            <div className="grid md:grid-cols-2 gap-2">
                                <div className="flex flex-col mb-3">
                                    <Field
                                        placeholder={t("communication.newMessage.subject")}
                                        name="subject"
                                        className="w-full border border-gray-200 focus:outline-none h-12 p-2 rounded"
                                    />

                                    <ErrorMessage
                                        name="subject"
                                        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                                    />
                                </div>

                                <div className="flex flex-col mb-3">
                                    <Field
                                        as="select"
                                        name="messageType"
                                        value={values.messageType}
                                        className="w-full border border-gray-200 focus:outline-none h-12 rounded bg-white"
                                    >
                                        <option value="" disabled>
                                            {t("communication.newMessage.select message type")}
                                        </option>
                                        {messageTypeOptions.map((type, ind) => {
                                            return (
                                                <option
                                                    value={type["name"][lang].toLowerCase()}
                                                    key={ind}
                                                >
                                                    {type["name"][lang]}
                                                </option>
                                            );
                                        })}
                                    </Field>

                                    <ErrorMessage
                                        name="messageType"
                                        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                                    />
                                </div>
                            </div>

                            {/*<div className="mb-3 flex lg:justify-end">*/}
                            {/*    <UploadFile*/}
                            {/*        props={{*/}
                            {/*            setFieldError,*/}
                            {/*            setFieldValue,*/}
                            {/*            setFieldTouched,*/}
                            {/*            values: values["attachments"],*/}
                            {/*            lang,*/}
                            {/*            fieldName: "attachments",*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}

                            <div className="flex flex-col mb-3 flex-grow">
                                <Field
                                    as="textarea"
                                    placeholder={t("communication.newMessage.message")}
                                    // row="2"
                                    name="message"
                                    className="w-full flex-grow border border-gray-200 focus:outline-none p-2 rounded"
                                />

                                <ErrorMessage
                                    name="message"
                                    render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                                />
                            </div>

                            <div className="flex justify-center items-center w-full">
                                <button
                                    type="submit"
                                    className="asideColor px-8 py-4 text-white rounded flex justify-center items-center md:w-1/4 w-full mb-2"
                                >
                                    {isLoading ? (
                                        <ButtonLoader />
                                    ) : (
                                        sendButton[lang]
                                    )}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>

            {open && <SnackMessage open={open} handleCloseSnack={handleClose} successMessage={snackMessage} />}

        </div>
    );
};

export default NewMessage;
