import React, { FC, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'

import { useTranslation } from "react-i18next";
import { ArrowRightOutlined } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import OpenedServiceModal from './OpenServiceModal'

import { AppLoader, Error } from '../Reusable'

type Props = {
  lang: "en" | "fr" | "rw";
  query: any
};

const ServiceRender: FC<Props> = ({ lang, query }) => {
  const { t } = useTranslation();

  const location  = useLocation();
  const navigate = useNavigate();

  const { search } = location

  const service_id = new URLSearchParams(search).get('service_id');

  const [openModal, setOpenModal] = useState(false);
  const [openedService, setOpenedService] = useState<any>(null);

  const handleOpenModal = (service: any) => {
    setOpenModal(true)
    setOpenedService(service);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('', { replace: true })
  } 

  const { isLoading, isError, error, refetch, data } = query;

  useEffect(() => {
    if (service_id) {
      setOpenModal(true);
      setOpenedService({
        id: service_id
      });
    }
  }, [service_id])

  if (isLoading) {
    return (
      <div className='flex justify-center items-center h-full'>
        <AppLoader />
      </div>
    )

  }

  if (isError) {
    return (
      <div className='flex justify-center items-center h-full'>
        <Error error={error} action={() => refetch()} />
      </div>
    )
  }

  const response = data?.data?.data;

  if (response?.data?.length === 0) {
    return (
      <div className="flex justify-center items-center text-lg font-bold text-gray-600 h-full">No services found...</div>
    )
  }

  return (
    <>
      {response?.data?.map((item: any, index: any) => {
        const departmentName = item?.jsonName[lang];
        const departmentDescription = item?.jsonDescription[lang];
        return (
          <div key={index} className="mb-3 bg-white rounded p-3 borderColor">
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                <p className="text-sm">
                  {departmentDescription}
                </p>
              }
            >
              <h2 className="mb-3 text-xl font-bold text-gray-600 cursor-pointer md:w-max">
                {departmentName}
              </h2>

            </Tooltip>


            {item?.divisions?.length > 0 ? (
              <div className="grid gap-4 lg:grid-row-4">
                {item?.divisions?.map((division: any) => {
                  const divisionName = division?.jsonName[lang]
                  return (
                    <div className="" key={division.id}>
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={
                          <p className="text-sm">
                            {divisionName?.length === 0 ? 'No Division name' : divisionName}
                          </p>
                        }
                      >
                        <p className="mb-3 text-sm font-bold tracking-wide text-gray-600 capitalize pl-3 md:w-max cursor-pointer">
                          {divisionName?.length === 0 ? 'No Division name' : divisionName}
                        </p>
                      </Tooltip>

                      <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
                        {division?.services?.length > 0 ? (
                          <>
                            {division?.services?.map((service: any) => {
                              const serviceName = service?.jsonName[lang];
                              const serviceDescription = service?.jsonDescription[lang];

                              return (
                                <div className="flex items-center text-gray-600" key={service.id}>
                                  <span className="textColor">
                                    <ArrowRightOutlined fontSize="large" />
                                  </span>

                                  <Tooltip
                                    disableFocusListener
                                    disableTouchListener
                                    title={
                                      <p className="text-sm">
                                        {serviceDescription}
                                      </p>
                                    }
                                  >
                                    <p
                                      className="pl-3 cursor-pointer hover:underline text-sm self-center"
                                      onClick={() => handleOpenModal(service)}
                                      key={service.id}
                                    >
                                      {serviceName}
                                    </p>
                                  </Tooltip>


                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <p className="text-gray-600 text-sm pl-3">
                            {t('service.no_service_division')}
                          </p>
                        )}
                      </div>

                    </div>
                  )
                }
                )}
              </div>
            ) : (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {item?.services?.length > 0 ? (
                  <>
                    {item?.services?.map((service: any) => {
                      const serviceName = service?.jsonName[lang];
                      const serviceDescription = service?.jsonDescription[lang];
                      return (
                        <div className="flex items-center text-gray-600" key={service.id}>
                          <span className="textColor">
                            <ArrowRightOutlined fontSize="large" />
                          </span>

                          <Tooltip
                            disableFocusListener
                            disableTouchListener
                            title={
                              <p className="text-sm">
                                {serviceDescription}
                              </p>
                            }
                          >
                            <p
                              className="pl-3 cursor-pointer hover:underline text-sm self-center"
                              onClick={() => handleOpenModal(service)}
                              key={service.id}
                            >
                              {serviceName}
                            </p>
                          </Tooltip>

                        </div>
                      );
                    })}
                  </>
                ) : (
                  <p className="text-gray-600 text-sm pl-3">
                    {t('service.no_service_department')}
                  </p>
                )}
              </div>
            )}
          </div>
        );
      })}

      {openModal && <OpenedServiceModal
        open={openModal}
        handleClose={handleCloseModal}
        openedService={openedService}
        lang={lang}
      />}
    </>
  );
};

export default ServiceRender;
